import type { PageContext } from "vike/types";

export default (pageContext: PageContext) => {
  const { search, pathname } = pageContext.urlParsed;

  const urlParts = pathname.slice(1).split("/");

  // Only URLs that start with `/password-reset/*`, have a proper token and email as param
  if (
    !(
      urlParts[0] === "password-reset" &&
      /[\\w\\d-]+/.test(urlParts[1]) &&
      search &&
      "email" in search
    )
  ) {
    return false;
  }

  const routeParams = {
    token: urlParts[1],
    email: search.email,
  };

  return {
    routeParams,
  };
};
