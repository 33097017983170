import type { PageContext } from "vike/types";

export default (pageContext: PageContext) => {
  const { search, pathname } = pageContext.urlParsed;

  const urlParts = pathname.slice(1).split("/");

  // Only URLs that start with `/register/verify/*`, have a length of 4 and contain the needed search params
  if (
    !(
      urlParts[0] === "register" &&
      urlParts[1] === "verify" &&
      urlParts.length === 4 &&
      search &&
      "expires" in search &&
      "signature" in search
    )
  ) {
    return false;
  }

  const routeParams = {
    id: urlParts[2],
    hash: urlParts[3],
    expires: search.expires,
    signature: search.signature,
  };

  return {
    routeParams,
  };
};
