import { render } from "vike/abort";
import type { PageContext } from "vike/types";
import type { Props } from "./props";

export default (pageContext: PageContext) => {
  // Parse the URL
  const urlParts = pageContext.urlPathname.slice(1).split("/");

  // Only URLs that start with `/gesetz/*`
  if (urlParts[0] !== "gesetz" || urlParts.length < 2) {
    return false;
  }

  if (!urlParts[2]) {
    throw render(`/gesetz/${urlParts[1]}/toc`);
  }

  const search = pageContext.urlParsed.search;
  let activeEntryType =
    (search.type as "schema" | "note" | undefined) ?? ("" as const);
  if (!activeEntryType && urlParts[3]) {
    activeEntryType = "schema";
  }

  return {
    routeParams: {
      legalTextSlug: urlParts[1],
      legalTextParagraphSlug: urlParts[2] === "toc" ? "" : urlParts[2],
      activeEntryId: search.id ?? "",
      activeEntryType,
      activeEntrySlug: urlParts[3] ?? "",
      oldParagraphId: search.oldParagraph ?? "",
    } satisfies Props,
  };
};
