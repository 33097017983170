import type { PageContext } from "vike/types";
import { redirect } from "vike/abort";

export default (pageContext: PageContext) => {
  // Parse the URL
  const urlParts = pageContext.urlPathname.slice(1).split("/");

  // Only URLs that start with `/bibliothek`
  if (urlParts[0] !== "bibliothek") {
    return false;
  }

  // Never allow the url to be only "bibliothek" without a tab selected
  if (!["inhalte", "chronik", "gesetze"].includes(urlParts[1])) {
    throw redirect("/bibliothek/gesetze");
  }

  return {
    routeParams: {
      view: urlParts[1] ?? "",
    },
  };
};
